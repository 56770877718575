import React from 'react';
import './App.css';
import {Col, Row, Typography, Card, Input } from 'antd';
const { Meta } = Card;
const { Search } = Input;
export default class App extends React.Component{

  constructor(props){
    super(props);
    this.state={
      carList: []
    };
  }

  componentDidMount() {
    this._getCars();
  }

  render() {
    const {carList} = this.state;
    const width = window.innerWidth;
    const height = window.innerHeight;

    return (
        <div
            style={{
                width: '100%',
                height: height,
                backgroundColor: '#282c34',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: 1280,
                    height: height - 50,
                    borderRadius: 30,
                    backgroundColor: '#ffffff',
                }}
            >
                <div
                    style={{
                        width: 400,
                        marginTop: 40,
                        marginLeft: 440
                    }}
                >
                    <Search
                        placeholder="搜索相关车型"
                        onSearch={value => console.log(value)}

                    />
                </div>
                <Row
                    style={{
                        marginTop: 80
                    }}
                >
                    {
                        carList.map((car, cIndex) => {
                            return (
                                <Col
                                    key={cIndex}
                                    span={8}
                                    style={{
                                        height: 240,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Card
                                        hoverable
                                        style={{ width: 240 }}
                                        cover={<img alt="example" src={car.url} />}
                                    >
                                        <Meta
                                            title={car.seriesName}
                                            description={car.powerType}
                                        />
                                    </Card>,
                                </Col>
                            )
                        })
                    }
                </Row>
                <div
                    style={{
                        width: 1280,
                        marginTop: 30,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <Typography.Text
                        style={{
                            color: '#000',
                            fontSize: '15pt'
                        }}
                    >
                        Copyright © 2021 edcturbo.cn | 梅香道  <a style={{color: '#1c8efe', fontSize: '15pt'}} href="https://beian.miit.gov.cn/#/Integrated/index">桂ICP备17004753号-2</a>
                    </Typography.Text>
                </div>
            </div>
        </div>
    );
  }

  /**
   * 获取车系
   * @private
   */
  _getCars = () => {
    const url = '/apiC/car/list/series';
    const finalKeyword = null;
    const powerTypeList = ['全部'];
    const selectedClass = ['全部'];
    const selectedBrand = ['全部'];
    const min = '0';
    const finalMax = '1000000000';
    const formData = new FormData();

    formData.append('keyword', finalKeyword);
    formData.append('vehicleType', selectedClass[0]);
    formData.append('powerType', powerTypeList[0]);
    formData.append('brandName', selectedBrand[0]);
    formData.append('minPriceStr', min);
    formData.append('maxPriceStr', finalMax);
    formData.append('sortBy', 'maxPrice');
    formData.append('sortOrder', null);
    formData.append('pageStr', '0');

    let isSuccess = false;
    fetch(url, {
      method: 'POST',
      headers: {

      },
      body: formData,
    }).then((response) => {
      if (response.ok === true) {
        isSuccess = response.ok;
        return response.json()
      }else {
        return null;
      }
    }).then((json) => {
      if (isSuccess) {
        this.setState({carList: json});
      }
    }).catch((error) => {
      console.log(error);
    });
  }
}
